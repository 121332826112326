<template>
  <div class="container">
    <el-form :model="s3form" :inline="true" class="demo-form-inline" :rules="s3formrules" ref="logform">
      <el-form-item label="前缀:" label-width="100px">
        <el-input style="width:400px" v-model.trim="s3form.prefix" @change="Search" placeholder=""></el-input>
      </el-form-item>
    </el-form>
    <el-form v-if="formVisibl" :model="form" :inline="true" class="demo-form-inline">
      <el-form-item label="appid分析:" label-width="100px">
        <el-input disabled style="width:400px" v-model="form.appid_equal" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="文件位置:" label-width="100px">
        <el-input disabled style="width:400px" v-model="form.file_brand" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="品牌分析:" label-width="100px">
        <el-input disabled style="width:400px" v-model="form.brand_equal" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="版本:" label-width="100px">
        <el-input disabled style="width:400px" v-model="form.version" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="区域:" label-width="100px">
        <el-input disabled style="width:400px" v-model="form.region" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="文件目录:" label-width="100px">
        <el-input disabled style="width:400px" v-model="form.file_key" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="目录分析:" label-width="100px">
        <el-input disabled style="width:400px" v-model="form.key_appid_right" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="位置分析:" label-width="100px">
        <el-input disabled style="width:400px" v-model="form.key_brand_right" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="设备型号:" label-width="100px">
        <el-input disabled style="width:400px" v-model="form.model_device_right" placeholder=""></el-input>
      </el-form-item>
    </el-form>
    <el-table border v-bind:data="s3KeyList">
      <el-table-column label="key">
        <template v-slot="scope">
          <span>{{ scope.row.key }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" width="200px">
        <template v-slot="scope">
          <el-button size="mini" type="info" @click="Down(scope.row)">下载 </el-button>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
      formVisibl: false,
      form: {
        appid_equal: '',
        file_brand: '',
        brand_equal: '',
        version: '',
        region: '',
        file_key: '',
        key_appid_right: '',
        key_brand_right: '',
        model_device_right:'',

      },
      s3form: {
        rows: 10,
        prefix: ''
      },
      s3formrules: {},
      s3KeyList: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    Search () {
      this.s3KeyList = []
      this.formVisibl = false
      this.api.FileLogListAnalyse({
        rows: 10,
        prefix: this.s3form.prefix
      }).then(res => {
        if (res.data.code == 200) {
          debugger
          this.formVisibl = true
          this.form.appid_equal = res.data.data.appid_equal
          this.form.file_brand = res.data.data.file_brand
          this.form.brand_equal = res.data.data.brand_equal
          this.form.version = res.data.data.version
          this.form.region = res.data.data.region
          this.form.file_key = res.data.data.file_key
          this.form.key_appid_right = res.data.data.key_appid_right
          this.form.key_brand_right = res.data.data.key_brand_right
          this.form.model_device_right = res.data.data.model_device_right
          this.s3KeyList = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Down(item) {
      this.api.FileLogDown({
        brand: this.form.file_brand,
        key: item.key
      }).then(res => {
        if (res.data.code == 200) {
          let imgurl = res.data.data.get_url
          let link = document.createElement('a');
          link.setAttribute('href', imgurl);
          link.setAttribute('target', '_blank');
          link.click()
          return
        }
        this.$message.error('下载失败' + res.data.msg)
      })
    }
  },
  filters: {},
  mounted () { },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
